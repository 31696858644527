import React from "react"
import { graphql } from "gatsby"
import { Button, TextField } from "@material-ui/core"
import CardLoteria from "../components/cardLoteria"

import "../styles/index.scss"
import SEO from "../components/seo"

export default (props) => {
  const [cardCount, setCardCount] = React.useState(1)
  const [renderCards, setRenderCards] = React.useState(false)

  const _handleCardCount = (e) => {
    setCardCount(e.target.value)
    setRenderCards(false)
  }

  const _handleSubmit = () => {
    setRenderCards(true)
  }

  const benefits = props.data.sanityBenefitList
  return (
    <>
      <SEO title="R&R Loteria Card Generator" />
      <div className="main-wrapper">
        <header className="header row justify-content-center align-items-center">
          <h1
            style={{
              color: "black",
              fontFamily: "greycliff_cfregular",
              fontSize: 200,
              textTransform: "uppercase",
              padding: 50,
            }}
          >
            <strong>R~R Loteria</strong>
          </h1>
        </header>
        <div className="row justify-content-center align-items-center card-count-wrapper">
          <div className="col-12 justify-content-center d-flex">
            <TextField
              label="How many Cards would you like to generate?"
              name="cardCount"
              type="number"
              className="col-6"
              onChange={_handleCardCount}
            />
          </div>
          <div className="col-12 justify-content-center d-flex">
            <Button
              onClick={_handleSubmit}
              style={{ marginTop: "25px" }}
              className="btn--primary-rr"
            >
              Generate
            </Button>
          </div>
        </div>
      </div>
      <div className="loteria-card-wrapper">
        <CardLoteria
          cardCount={cardCount}
          renderCards={renderCards}
          data={benefits && benefits}
        />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query {
    sanityBenefitList {
      benefit
    }
  }
`
